<template>
  <div>
    <div v-if="customer">
      <div class="two fields">
        <div class="field">
          <label for="customerName" class="label">Titular</label>
          <input type="text" id="customerName" name="customer[name]" class="ui input disabled" disabled :value="customer.fullName" />
        </div>

        <div class="field">
          <label for="customerCpf" class="label">CPF</label>
          <input type="text" id="customerCpf" name="customer[cpf]" class="ui input disabled" disabled :value="customer.identification" />
        </div>
      </div>

    </div>

    <div v-if="address">
      <div class="two fields">
        <div class="field">
          <label for="addressStreet" class="label">Endereço</label>
          <input type="text" id="addressStreet" name="address[street]" class="ui input disabled" disabled :value="streetNumber" />
        </div>

        <div class="field">
          <label for="addressNeighborhood" class="label">Bairro</label>
          <input type="text" id="addressNeighborhood" name="address[neighborhood]" class="ui input disabled" disabled maxlength="14" :value="address.neighborhood" />
        </div>
      </div>

      <div class="two fields">
        <div class="field">
          <label for="addressAditionInfo" class="label">Complemento</label>
          <input type="text" id="addressAditionInfo" name="address[aditioninfo]" class="ui input disabled" disabled :value="address.complement" />
        </div>

        <div class="field">
          <label id="addressZipCode" class="label">CEP</label>
          <input type="text" id="addressZipCode" name="address[zipcode]" class="ui input disabled" disabled maxlength="14" :value="address.zipcode" />
        </div>
      </div>

      <div class="two fields">
        <div class="field">
          <label for="addressCity" class="label">Complemento</label>
          <input type="text" id="addressCity" name="address[city]" class="ui input disabled" disabled :value="address.city" />
        </div>

        <div class="field">
          <label for="addressState" class="label">Estado</label>
          <input type="text" id="addressState" name="address[state]" class="ui input disabled" disabled :value="address.state" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'TFormBankSlip',
  data () {
    return {
    }
  },

  computed: {
    ...mapState({
      transactionData: ({ transactions }) => transactions.transactionData,
      customer: ({ transactions }) => transactions.transactionData.customer,
      paymentBankSlipInfo: ({ transactions }) => transactions.transactionData.paymentBankSlipInfo,
      address: ({ transactions }) => transactions.transactionData.address,
    }),

    streetNumber: ({ address }) => `${address.street}, ${address.number}`
  }
}
</script>
